import request from "@/utils/request";

const api = {
  getSiteVideo: "/dsmcore/api/worksite/camera/video", // 查看工地视频
  stopSiteVideo: "/dsmcore/api/worksite/camera/unvideo", // 断开工地视频
  onCall: "/dsmcore/api/worksite/camera/call", // 通话请求
  offCall: "/dsmcore/api/worksite/camera/uncall", // 通话结束
  cameraMove: "/dsmcore/api/worksite/camera/move", // 移动摄像头
  cameraReset: "/dsmcore/api/worksite/camera/reset", // 摄像头复位
  cameraRestart: "/dsmcore/api/worksite/camera/restart", // 摄像头重启
  cameraStatus: "/dsmcore/api/worksite/camera/status", // 摄像头状态
  getEvent: "/dsmcore/api/worksite/event", // 查询事件
  playbackStart: "/dsmcore/api/worksite/playbackstart", // 回放请求 工地查看回放
  playbackIndex: "/dsmcore/api/worksite/playbackindex", // 工地回放索引
  Playback: "/files/api/worksite/playback", // 回放请求 工地视频获取
  videoSignal: "dsmcore/api/device/signal", //获取摄像机信号强度
  getSitPreset: "/dsmcore/api/worksite/camera/preset", //设置摄像头初始位置
  historyList: "/dsmcore/api/devicePreset/historyList", //查询预置位历史记录
  wsbinddevices: "/dsmcore/api/device/wsbinddevices", //查询工地设备信息
  setdefault: "/dsmcore/api/device/setdefault", //设置默认设备
};

export function GetSiteVideo(parameter) {
  return request({
    url: api.getSiteVideo,
    method: "post",
    params: parameter,
  });
  // return new Promise((resolve) => {
  //   const data = {
  //     Msg: "",
  //     Code: "200",
  //     Data: {
  //       video_url: "rtmp://39.107.116.3:1935/stream/1636874302720",
  //       com_only: "d454e0d0-7365-4e72-b856-8e2ef2408551",
  //       audio_url: "rtmp://39.107.116.3:1935/stream/1636874302713",
  //     },
  //     Code: 200,
  //   };
  //   resolve(data);
  // });
}

export function StopSiteVideo(parameter) {
  return request({
    url: api.stopSiteVideo,
    method: "post",
    params: parameter,
  });
}
export function OnCall(parameter) {
  return request({
    url: api.onCall,
    method: "post",
    params: parameter,
  });
}
export function OffCall(parameter) {
  return request({
    url: api.offCall,
    method: "post",
    params: parameter,
  });
}
export function CameraMove(parameter) {
  return request({
    url: api.cameraMove,
    method: "post",
    params: parameter,
  });
}
export function CameraReset(parameter) {
  return request({
    url: api.cameraReset,
    method: "post",
    params: parameter,
  });
}
export function CameraRestart(parameter) {
  return request({
    url: api.cameraRestart,
    method: "post",
    params: parameter,
  });
}
export function CameraStatus(parameter) {
  return request({
    url: api.cameraStatus,
    method: "post",
    params: parameter,
  });
}
// 回放
export function GetEvent(parameter) {
  return request({
    url: api.getEvent,
    method: "post",
    params: parameter,
  });
}
export function PlaybackStart(parameter) {
  return request({
    url: api.playbackStart,
    method: "post",
    params: parameter,
  });
}
export function PlaybackIndex(parameter) {
  return request({
    url: api.playbackIndex,
    method: "post",
    params: parameter,
  });
}
export function Playback(parameter) {
  return request({
    url: api.Playback,
    method: "post",
    params: parameter,
  });
}
export function videoSignal(parameter) {
  return request({
    url: api.videoSignal,
    method: "post",
    params: parameter,
  });
}
// 设置摄像头初始位置
export function getSitPreset(parameter) {
  return request({
    url: api.getSitPreset,
    method: "post",
    params: parameter,
  });
}

export function GethistoryList(parameter) {
  return request({
    url: api.historyList,
    method: "post",
    params: parameter,
  });
}
// 查询工地绑定设备信息
export function GetWsbinddevices(parameter) {
  return request({
    url: api.wsbinddevices,
    method: "post",
    params: parameter,
  });
}
// 设置默认设备
export function Setdefault(parameter) {
  return request({
    url: api.setdefault,
    method: "post",
    params: parameter,
  });
}
